import React from 'react';
import Helmet from 'react-helmet';
import {FaChevronLeft} from 'react-icons/fa';
import {FaChevronRight} from 'react-icons/fa';
import { graphql } from 'gatsby'

import Clink from '../components/Link';
import Tags from '../components/Tags';
import Layout from '../components/Layout';

import '../css/blog-post.css';
import '../css/images.css';

export default function Template(props) {
    const { data, pageContext } = props;
    const { markdownRemark: post } = data;
    const { next, prev } = pageContext;
    return (
        <Layout {...props}>
            <div className="blog-post-container">
                <Helmet
                    title={` JV Blog - ${post.frontmatter.title}`}
                />
                <article className="blog-post">
                    <div className="blog-post-content">
                        <h1 className="title">
                            {post.frontmatter.title}
                        </h1>
                        <h4 className="date">
                            {post.frontmatter.date}
                        </h4>
                        <Tags list={post.frontmatter.tags || []} />
                        <div
                            className="blog-post-content"
                            dangerouslySetInnerHTML={{ __html: post.html }}
                        />
                        <div className="navigation">
                            {prev &&
                                <Clink className="link prev" to={prev.frontmatter.path}>
                                    <FaChevronLeft /> {prev.frontmatter.title}
                                </Clink>}
                            {next &&
                                <Clink className="link next" to={next.frontmatter.path}>
                                    {next.frontmatter.title} <FaChevronRight />
                                </Clink>}
                        </div>
                    </div>
                </article>
            </div>
        </Layout>
    );
}

export const pageQuery = graphql`
    query BlogPostByPath($path: String!) {
        markdownRemark(
            frontmatter: {
                path: {
                    eq: $path
                }
                type: {
                    ne: null
                }
            }
        ){
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                path
                tags
                title
            }
        }
    }
`;
